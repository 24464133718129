#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1230px 501px #7ec845, 1961px 1903px #7ec845, 1162px 891px #7ec845,
    827px 1318px #7ec845, 470px 72px #7ec845, 1279px 842px #7ec845,
    497px 813px #7ec845, 1320px 544px #7ec845, 1030px 782px #7ec845,
    932px 135px #7ec845, 1040px 1343px #7ec845, 1899px 543px #7ec845,
    706px 1483px #7ec845, 1129px 949px #7ec845, 996px 1208px #7ec845,
    965px 590px #7ec845, 526px 280px #7ec845, 1980px 351px #7ec845,
    614px 1325px #7ec845, 307px 1952px #7ec845, 1300px 1983px #7ec845,
    789px 676px #7ec845, 1246px 53px #7ec845, 1702px 1919px #7ec845,
    585px 1211px #7ec845, 816px 507px #7ec845, 1142px 7px #7ec845,
    774px 1823px #7ec845, 1331px 1517px #7ec845, 1645px 549px #7ec845,
    644px 1680px #7ec845, 11px 1482px #7ec845, 1063px 113px #7ec845,
    1885px 616px #7ec845, 1566px 1095px #7ec845, 1179px 1907px #7ec845,
    782px 1131px #7ec845, 814px 1263px #7ec845, 538px 210px #7ec845,
    1164px 306px #7ec845, 1124px 1043px #7ec845, 506px 1374px #7ec845,
    540px 1500px #7ec845, 1679px 1842px #7ec845, 1390px 975px #7ec845,
    1140px 1062px #7ec845, 264px 1087px #7ec845, 422px 457px #7ec845,
    384px 565px #7ec845, 216px 1545px #7ec845, 1492px 1732px #7ec845,
    496px 1815px #7ec845, 1816px 1538px #7ec845, 1048px 500px #7ec845,
    808px 116px #7ec845, 215px 312px #7ec845, 488px 823px #7ec845,
    1375px 1080px #7ec845, 1581px 1245px #7ec845, 1812px 368px #7ec845,
    729px 557px #7ec845, 1250px 1991px #7ec845, 366px 1071px #7ec845,
    204px 390px #7ec845, 1939px 1802px #7ec845, 711px 147px #7ec845,
    262px 190px #7ec845, 232px 1776px #7ec845, 1455px 1123px #7ec845,
    91px 1214px #7ec845, 1035px 425px #7ec845, 1734px 1589px #7ec845,
    190px 384px #7ec845, 905px 1585px #7ec845, 964px 932px #7ec845,
    18px 1154px #7ec845, 1105px 667px #7ec845, 266px 1941px #7ec845,
    629px 1643px #7ec845, 554px 1509px #7ec845, 844px 1220px #7ec845,
    1473px 933px #7ec845, 1531px 35px #7ec845, 1967px 1145px #7ec845,
    1123px 29px #7ec845, 1759px 1865px #7ec845, 1009px 1880px #7ec845,
    1705px 1845px #7ec845, 148px 842px #7ec845, 1597px 315px #7ec845,
    195px 551px #7ec845, 1586px 851px #7ec845, 260px 53px #7ec845,
    822px 299px #7ec845, 1207px 1560px #7ec845, 800px 1450px #7ec845,
    461px 147px #7ec845, 1323px 913px #7ec845, 378px 516px #7ec845,
    1530px 1615px #7ec845;
  animation: animStar 150s linear infinite;
}

#stars3:before {
  content: "";
  position: fixed;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1230px 501px #7ec845, 1961px 1903px #7ec845, 1162px 891px #7ec845,
    827px 1318px #7ec845, 470px 72px #7ec845, 1279px 842px #7ec845,
    497px 813px #7ec845, 1320px 544px #7ec845, 1030px 782px #7ec845,
    932px 135px #7ec845, 1040px 1343px #7ec845, 1899px 543px #7ec845,
    706px 1483px #7ec845, 1129px 949px #7ec845, 996px 1208px #7ec845,
    965px 590px #7ec845, 526px 280px #7ec845, 1980px 351px #7ec845,
    614px 1325px #7ec845, 307px 1952px #7ec845, 1300px 1983px #7ec845,
    789px 676px #7ec845, 1246px 53px #7ec845, 1702px 1919px #7ec845,
    585px 1211px #7ec845, 816px 507px #7ec845, 1142px 7px #7ec845,
    774px 1823px #7ec845, 1331px 1517px #7ec845, 1645px 549px #7ec845,
    644px 1680px #7ec845, 11px 1482px #7ec845, 1063px 113px #7ec845,
    1885px 616px #7ec845, 1566px 1095px #7ec845, 1179px 1907px #7ec845,
    782px 1131px #7ec845, 814px 1263px #7ec845, 538px 210px #7ec845,
    1164px 306px #7ec845, 1124px 1043px #7ec845, 506px 1374px #7ec845,
    540px 1500px #7ec845, 1679px 1842px #7ec845, 1390px 975px #7ec845,
    1140px 1062px #7ec845, 264px 1087px #7ec845, 422px 457px #7ec845,
    384px 565px #7ec845, 216px 1545px #7ec845, 1492px 1732px #7ec845,
    496px 1815px #7ec845, 1816px 1538px #7ec845, 1048px 500px #7ec845,
    808px 116px #7ec845, 215px 312px #7ec845, 488px 823px #7ec845,
    1375px 1080px #7ec845, 1581px 1245px #7ec845, 1812px 368px #7ec845,
    729px 557px #7ec845, 1250px 1991px #7ec845, 366px 1071px #7ec845,
    204px 390px #7ec845, 1939px 1802px #7ec845, 711px 147px #7ec845,
    262px 190px #7ec845, 232px 1776px #7ec845, 1455px 1123px #7ec845,
    91px 1214px #7ec845, 1035px 425px #7ec845, 1734px 1589px #7ec845,
    190px 384px #7ec845, 905px 1585px #7ec845, 964px 932px #7ec845,
    18px 1154px #7ec845, 1105px 667px #7ec845, 266px 1941px #7ec845,
    629px 1643px #7ec845, 554px 1509px #7ec845, 844px 1220px #7ec845,
    1473px 933px #7ec845, 1531px 35px #7ec845, 1967px 1145px #7ec845,
    1123px 29px #7ec845, 1759px 1865px #7ec845, 1009px 1880px #7ec845,
    1705px 1845px #7ec845, 148px 842px #7ec845, 1597px 315px #7ec845,
    195px 551px #7ec845, 1586px 851px #7ec845, 260px 53px #7ec845,
    822px 299px #7ec845, 1207px 1560px #7ec845, 800px 1450px #7ec845,
    461px 147px #7ec845, 1323px 913px #7ec845, 378px 516px #7ec845,
    1530px 1615px #7ec845;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
