#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 735px 1335px #c0d725, 1341px 824px #c0d725, 1410px 1324px #c0d725,
    1255px 148px #c0d725, 916px 840px #c0d725, 1602px 659px #c0d725,
    1150px 1344px #c0d725, 813px 1438px #c0d725, 5px 293px #c0d725,
    1403px 385px #c0d725, 1194px 1512px #c0d725, 1285px 1018px #c0d725,
    399px 1880px #c0d725, 1552px 227px #c0d725, 1185px 660px #c0d725,
    1644px 320px #c0d725, 1114px 1995px #c0d725, 1331px 936px #c0d725,
    1228px 193px #c0d725, 931px 527px #c0d725, 1944px 531px #c0d725,
    892px 421px #c0d725, 524px 779px #c0d725, 61px 1933px #c0d725,
    755px 1719px #c0d725, 567px 735px #c0d725, 1013px 341px #c0d725,
    982px 1389px #c0d725, 249px 1701px #c0d725, 903px 742px #c0d725,
    263px 93px #c0d725, 389px 826px #c0d725, 1390px 1561px #c0d725,
    708px 1608px #c0d725, 654px 220px #c0d725, 806px 125px #c0d725,
    480px 727px #c0d725, 966px 484px #c0d725, 1615px 1375px #c0d725,
    1425px 581px #c0d725, 364px 414px #c0d725, 1278px 1307px #c0d725,
    1683px 1059px #c0d725, 24px 1564px #c0d725, 1185px 1886px #c0d725,
    780px 1496px #c0d725, 1280px 1444px #c0d725, 1526px 60px #c0d725,
    1425px 526px #c0d725, 658px 545px #c0d725, 890px 1680px #c0d725,
    117px 909px #c0d725, 433px 162px #c0d725, 145px 189px #c0d725,
    1947px 957px #c0d725, 636px 770px #c0d725, 1867px 161px #c0d725,
    945px 1930px #c0d725, 1803px 1313px #c0d725, 912px 1200px #c0d725,
    549px 798px #c0d725, 1791px 142px #c0d725, 1685px 1601px #c0d725,
    1622px 522px #c0d725, 779px 701px #c0d725, 607px 80px #c0d725,
    1002px 292px #c0d725, 1369px 1908px #c0d725, 1619px 1770px #c0d725,
    1718px 1918px #c0d725, 1911px 1732px #c0d725, 729px 1083px #c0d725,
    81px 326px #c0d725, 1781px 1789px #c0d725, 70px 1765px #c0d725,
    947px 1927px #c0d725, 1672px 1953px #c0d725, 923px 1728px #c0d725,
    184px 80px #c0d725, 1198px 1416px #c0d725, 869px 1562px #c0d725,
    1121px 830px #c0d725, 1318px 1467px #c0d725, 619px 811px #c0d725,
    405px 532px #c0d725, 898px 391px #c0d725, 509px 1379px #c0d725,
    390px 1070px #c0d725, 1959px 223px #c0d725, 26px 1375px #c0d725,
    1376px 1265px #c0d725, 507px 234px #c0d725, 600px 1919px #c0d725,
    262px 1511px #c0d725, 375px 98px #c0d725, 988px 1248px #c0d725,
    1551px 1532px #c0d725, 1693px 517px #c0d725, 10px 815px #c0d725,
    417px 38px #c0d725, 1552px 236px #c0d725, 187px 352px #c0d725,
    1838px 853px #c0d725, 1409px 1448px #c0d725, 182px 1355px #c0d725,
    1416px 321px #c0d725, 1963px 1294px #c0d725, 1849px 253px #c0d725,
    1048px 259px #c0d725, 1317px 37px #c0d725, 1170px 461px #c0d725,
    1249px 977px #c0d725, 409px 591px #c0d725, 1405px 42px #c0d725,
    1573px 1935px #c0d725, 67px 1849px #c0d725, 1392px 1447px #c0d725,
    1007px 1079px #c0d725, 1778px 1880px #c0d725, 205px 1378px #c0d725,
    277px 1994px #c0d725, 276px 396px #c0d725, 556px 1258px #c0d725,
    1412px 391px #c0d725, 1495px 970px #c0d725, 1336px 1294px #c0d725,
    1131px 1291px #c0d725, 1731px 451px #c0d725, 832px 348px #c0d725,
    775px 947px #c0d725, 1573px 1008px #c0d725, 1072px 354px #c0d725,
    1454px 398px #c0d725, 643px 1293px #c0d725, 1913px 510px #c0d725,
    1634px 1392px #c0d725, 1782px 153px #c0d725, 1169px 647px #c0d725,
    1749px 881px #c0d725, 1690px 100px #c0d725, 1092px 1801px #c0d725,
    137px 1770px #c0d725, 151px 513px #c0d725, 449px 824px #c0d725,
    1622px 799px #c0d725, 715px 282px #c0d725, 1209px 101px #c0d725,
    1608px 1897px #c0d725, 1685px 1186px #c0d725, 79px 1797px #c0d725,
    1360px 316px #c0d725, 1973px 1999px #c0d725, 1565px 1436px #c0d725,
    1346px 1399px #c0d725, 661px 1045px #c0d725, 802px 418px #c0d725,
    1914px 503px #c0d725, 252px 660px #c0d725, 149px 1280px #c0d725,
    643px 1917px #c0d725, 162px 978px #c0d725, 1977px 61px #c0d725,
    1981px 1893px #c0d725, 1559px 892px #c0d725, 1665px 1066px #c0d725,
    1315px 429px #c0d725, 1425px 68px #c0d725, 994px 1379px #c0d725,
    553px 520px #c0d725, 289px 1897px #c0d725, 1849px 1045px #c0d725,
    365px 976px #c0d725, 323px 786px #c0d725, 646px 1394px #c0d725,
    779px 1003px #c0d725, 685px 833px #c0d725, 85px 1039px #c0d725,
    1301px 974px #c0d725, 1756px 1305px #c0d725, 1779px 1840px #c0d725,
    35px 1850px #c0d725, 1454px 452px #c0d725, 1767px 379px #c0d725,
    699px 460px #c0d725, 232px 550px #c0d725, 670px 216px #c0d725,
    263px 1415px #c0d725, 610px 321px #c0d725, 1974px 79px #c0d725,
    55px 164px #c0d725, 1163px 221px #c0d725, 1403px 917px #c0d725,
    1625px 96px #c0d725, 151px 771px #c0d725, 1446px 1109px #c0d725,
    651px 550px #c0d725, 626px 1573px #c0d725, 1406px 1300px #c0d725,
    1185px 1017px #c0d725, 1502px 939px #c0d725;
  animation: animStar 100s linear infinite;
}

#stars2:before {
  content: "";
  position: fixed;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 735px 1335px #c0d725, 1341px 824px #c0d725, 1410px 1324px #c0d725,
    1255px 148px #c0d725, 916px 840px #c0d725, 1602px 659px #c0d725,
    1150px 1344px #c0d725, 813px 1438px #c0d725, 5px 293px #c0d725,
    1403px 385px #c0d725, 1194px 1512px #c0d725, 1285px 1018px #c0d725,
    399px 1880px #c0d725, 1552px 227px #c0d725, 1185px 660px #c0d725,
    1644px 320px #c0d725, 1114px 1995px #c0d725, 1331px 936px #c0d725,
    1228px 193px #c0d725, 931px 527px #c0d725, 1944px 531px #c0d725,
    892px 421px #c0d725, 524px 779px #c0d725, 61px 1933px #c0d725,
    755px 1719px #c0d725, 567px 735px #c0d725, 1013px 341px #c0d725,
    982px 1389px #c0d725, 249px 1701px #c0d725, 903px 742px #c0d725,
    263px 93px #c0d725, 389px 826px #c0d725, 1390px 1561px #c0d725,
    708px 1608px #c0d725, 654px 220px #c0d725, 806px 125px #c0d725,
    480px 727px #c0d725, 966px 484px #c0d725, 1615px 1375px #c0d725,
    1425px 581px #c0d725, 364px 414px #c0d725, 1278px 1307px #c0d725,
    1683px 1059px #c0d725, 24px 1564px #c0d725, 1185px 1886px #c0d725,
    780px 1496px #c0d725, 1280px 1444px #c0d725, 1526px 60px #c0d725,
    1425px 526px #c0d725, 658px 545px #c0d725, 890px 1680px #c0d725,
    117px 909px #c0d725, 433px 162px #c0d725, 145px 189px #c0d725,
    1947px 957px #c0d725, 636px 770px #c0d725, 1867px 161px #c0d725,
    945px 1930px #c0d725, 1803px 1313px #c0d725, 912px 1200px #c0d725,
    549px 798px #c0d725, 1791px 142px #c0d725, 1685px 1601px #c0d725,
    1622px 522px #c0d725, 779px 701px #c0d725, 607px 80px #c0d725,
    1002px 292px #c0d725, 1369px 1908px #c0d725, 1619px 1770px #c0d725,
    1718px 1918px #c0d725, 1911px 1732px #c0d725, 729px 1083px #c0d725,
    81px 326px #c0d725, 1781px 1789px #c0d725, 70px 1765px #c0d725,
    947px 1927px #c0d725, 1672px 1953px #c0d725, 923px 1728px #c0d725,
    184px 80px #c0d725, 1198px 1416px #c0d725, 869px 1562px #c0d725,
    1121px 830px #c0d725, 1318px 1467px #c0d725, 619px 811px #c0d725,
    405px 532px #c0d725, 898px 391px #c0d725, 509px 1379px #c0d725,
    390px 1070px #c0d725, 1959px 223px #c0d725, 26px 1375px #c0d725,
    1376px 1265px #c0d725, 507px 234px #c0d725, 600px 1919px #c0d725,
    262px 1511px #c0d725, 375px 98px #c0d725, 988px 1248px #c0d725,
    1551px 1532px #c0d725, 1693px 517px #c0d725, 10px 815px #c0d725,
    417px 38px #c0d725, 1552px 236px #c0d725, 187px 352px #c0d725,
    1838px 853px #c0d725, 1409px 1448px #c0d725, 182px 1355px #c0d725,
    1416px 321px #c0d725, 1963px 1294px #c0d725, 1849px 253px #c0d725,
    1048px 259px #c0d725, 1317px 37px #c0d725, 1170px 461px #c0d725,
    1249px 977px #c0d725, 409px 591px #c0d725, 1405px 42px #c0d725,
    1573px 1935px #c0d725, 67px 1849px #c0d725, 1392px 1447px #c0d725,
    1007px 1079px #c0d725, 1778px 1880px #c0d725, 205px 1378px #c0d725,
    277px 1994px #c0d725, 276px 396px #c0d725, 556px 1258px #c0d725,
    1412px 391px #c0d725, 1495px 970px #c0d725, 1336px 1294px #c0d725,
    1131px 1291px #c0d725, 1731px 451px #c0d725, 832px 348px #c0d725,
    775px 947px #c0d725, 1573px 1008px #c0d725, 1072px 354px #c0d725,
    1454px 398px #c0d725, 643px 1293px #c0d725, 1913px 510px #c0d725,
    1634px 1392px #c0d725, 1782px 153px #c0d725, 1169px 647px #c0d725,
    1749px 881px #c0d725, 1690px 100px #c0d725, 1092px 1801px #c0d725,
    137px 1770px #c0d725, 151px 513px #c0d725, 449px 824px #c0d725,
    1622px 799px #c0d725, 715px 282px #c0d725, 1209px 101px #c0d725,
    1608px 1897px #c0d725, 1685px 1186px #c0d725, 79px 1797px #c0d725,
    1360px 316px #c0d725, 1973px 1999px #c0d725, 1565px 1436px #c0d725,
    1346px 1399px #c0d725, 661px 1045px #c0d725, 802px 418px #c0d725,
    1914px 503px #c0d725, 252px 660px #c0d725, 149px 1280px #c0d725,
    643px 1917px #c0d725, 162px 978px #c0d725, 1977px 61px #c0d725,
    1981px 1893px #c0d725, 1559px 892px #c0d725, 1665px 1066px #c0d725,
    1315px 429px #c0d725, 1425px 68px #c0d725, 994px 1379px #c0d725,
    553px 520px #c0d725, 289px 1897px #c0d725, 1849px 1045px #c0d725,
    365px 976px #c0d725, 323px 786px #c0d725, 646px 1394px #c0d725,
    779px 1003px #c0d725, 685px 833px #c0d725, 85px 1039px #c0d725,
    1301px 974px #c0d725, 1756px 1305px #c0d725, 1779px 1840px #c0d725,
    35px 1850px #c0d725, 1454px 452px #c0d725, 1767px 379px #c0d725,
    699px 460px #c0d725, 232px 550px #c0d725, 670px 216px #c0d725,
    263px 1415px #c0d725, 610px 321px #c0d725, 1974px 79px #c0d725,
    55px 164px #c0d725, 1163px 221px #c0d725, 1403px 917px #c0d725,
    1625px 96px #c0d725, 151px 771px #c0d725, 1446px 1109px #c0d725,
    651px 550px #c0d725, 626px 1573px #c0d725, 1406px 1300px #c0d725,
    1185px 1017px #c0d725, 1502px 939px #c0d725;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
